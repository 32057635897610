<template>
  <div class="login">
    <div class="left"><img class="background" src="./images/img_bg.png" /></div>
    <div class="login-page">
      <div>
        <div class="header">
        <img
          src="./images/logo_gs.png"
          alt
        />
      </div>

      <div class="main">
        <div class="content">
          <div class="title">Welcome Back！</div>
          <!-- <div class="title2">登录</div> -->
          <input class="input" type="text" oninput="this.value=this.value.replace(/\s+/g,'')" v-model="params.username" placeholder="请输入登录账号" />
          <input type="text" style="display: none"/>
          <div class="show_pass">
            <input class="input" oninput="this.value=this.value.replace(/\s+/g,'')" type="text"  placeholder="请输入登录密码" v-if="pwdType" v-model="params.pwd">
            <input class="input" oninput="this.value=this.value.replace(/\s+/g,'')" type="password" placeholder="请输入登录密码" v-model="params.pwd" v-else > 
            <div class="show_img">
              <img :src="seen ? seenImg : unseenImg" @click="changeType()" class="eye_img" />
            </div>
          </div>
          <el-checkbox class="remember-code" v-model="checkedLogin">记住密码</el-checkbox>
          <div class="remember-forget" @click="forgetPwd">忘记密码</div>
          <div class="submit" @click="handleLodin">登录</div>
        </div>
      </div>
      </div>
    </div>
    <!--弹框-->
    <el-dialog
      title="忘记密码"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="360px"
    >
      <div class="forget-pwd-tip">如忘记密码，请联系繁星平台工作人员重置！</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeForget">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "login",
  data() {
    return {
      seen:'',
      unseenImg:require('@/assets/images/login_icon_eye_close@2x.png'),//看不见
      seenImg :require('@/assets/images/login_icon_eye_@2x.png'),//看得见密码
      isLoading: false,
      pwdType:false,
      dialogVisible: false,
      checkedLogin: true,
      params: {
        username: '',
        pwd: ''
      }
    };
  },
  created () {
    if (JSON.parse(localStorage.getItem('loginUser'))) {
      this.params.username = JSON.parse(localStorage.getItem('loginUser')).username
      this.params.pwd = JSON.parse(localStorage.getItem('loginUser')).pwd
      this.checkedLogin = JSON.parse(localStorage.getItem('loginUser')).checkedLogin
    }
  },
  mounted() {
    window.addEventListener('keydown',this.keyDown);
  },
  methods: {
    //密码的显示隐藏
    changeType:function(){
      this.seen = !this.seen;//小眼睛的变化
      this.pwdType=!this.pwdType;//跟着小眼睛变化，密码框隐藏显示文本框，内容就显示了
    },
    handleLodin() {
      if(!this.params.username||!this.params.pwd){
        return this.$message.error('请输入登录账号或密码')
      }
      const pcLoginRequest = {
        pageNum: 0,
        pageSize: 0,
        userName: this.params.username,
        password: this.params.pwd,
        token:""
      };
      if (this.params.username !== "" && this.params.pwd !== "") {
        _api.postLogin(pcLoginRequest).then(res => {
          //请求返回来的数据
          console.log(res);
          //  保存token：
          if (res.code === 1) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userName", this.params.username);
            if (this.checkedLogin) {
              let userForm = this.params
              console.log(userForm);
              userForm.checkedLogin = this.checkedLogin
              localStorage.setItem('loginUser', JSON.stringify(userForm))
            } else {
              localStorage.removeItem('loginUser')
            }
            this.$router.push({ path: "/dashboard" });
            this.$message({
              showClose: true,
              message: "登录成功",
              type: "success"
            });
          } else {
            //  登录失败
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error"
            });
          }
        });
      // } else {
      //   this.$message({
      //     showClose: true,
      //     message: "用户名和密码不能为空",
      //     type: "error"
      //   });
      }
    },
    keyDown(e){
        //如果是回车则执行登录方法
      if(e.keyCode == 13){
        this.handleLodin();
      }
    },
    // 忘记密码
    forgetPwd() {
      this.dialogVisible = true
    },
    closeForget() {
      this.dialogVisible = false
    }
  },
  destroyed(){
    window.removeEventListener('keydown',this.keyDown,false);
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.login {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  min-height: 700px;
  display: flex;
  align-items: center;
  overflow: hidden;
  .left{
    // width: 50%;
    // height: 100vh;
  }
  .background {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .login-page {
    background: url("./images/img_bg2.png");
    width: 50%;
    height: 100%;
    // position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .header {
      // position: absolute;
      right: 300px;
      top: 80px;
      display: flex;
      align-items: center;
      > img {
        width: 600px;
        height: 61px;
        margin-bottom: 40px;
      }
      > span {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #0981ff;
      }
    }

    .main {
      // position: absolute;
      width: 560px;
      height: 620px;
      background: #0981ff;
      box-shadow: 0px 5px 96px 0px rgba(78, 130, 185, 0.1),
        0px 30px 70px 0px rgba(78, 130, 185, 0.27);
      border-radius: 20px;
      padding: 8px 0 0 0;
      .content {
        width: 560px;
        height: 612px;
        background: #ffffff;
        border-radius: 20px;
        padding: 50px 50px 0px 50px;
        .title {
          font-size: 46px;
          font-family: PingFang SC;
          // font-weight: bold;
          color: #333333;
          margin-bottom: 40px;
        }

        .title2 {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }

        .input {
          display: block;
          width: 100%;
          border: none;
          border-bottom: 1px solid #e7eaee;
          padding: 40px 0;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          outline: none;
          &::placeholder {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #b6b9d0;
            outline: none;
          }
        }

        .remember-code {
          margin: 20px 0 53px 0;
        }
        .remember-forget{
          margin: 20px 0 53px 0;
          float: right;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          text-decoration: underline;
          color: #0981FF;
          cursor: pointer;
        }
        .submit {
          width: 460px;
          height: 60px;
          background: #0981ff;
          border-radius: 10px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .footer {
      position: absolute;
      left: 52px;
      bottom: 39px;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #0981ff;
    }
  }
  .show_pass{
    position: relative;
    .show_img{
      position: absolute;
      top: 40%;
      right: 5px;
      width: 25px;
      height: 25px;
    }
  }
  .forget-pwd-tip{
    text-align: center;
    font-size: 16px;
    color: #333333;
    margin: 20px 0;
  }
  .forget-pwd-btn{
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
}
</style>
